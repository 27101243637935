import React from 'react';
import logoimg from "../assets/img/logo-3.png";
import logoWhite from "../assets/img/logo-white.png";


function Logo({white = false}) {
    return (
        <div>
            <a href="/">
                <img
                    src={white ? logoWhite : logoimg}
                    alt="logo"
                    className="w-[100px] "
                />
            </a>
        </div>
    );
}

export default Logo;