import React, { useRef, useState, useEffect } from 'react';
import home from '../assets/img/Rectangle.png';
import rect from '../assets/img/Rectangle2.png';
import phone from '../assets/img/app1.png';
import miniphone from '../assets/img/app2.png';
import man from '../assets/img/man.png';
import manReverse from '../assets/img/man-reverse.png';
import video from '../assets/partners-video.mp4';
import { UsersIcon, AdjustmentsHorizontalIcon, CheckIcon, PlayCircleIcon, PauseCircleIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from 'react-router-dom';


const Home = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const navigate = useNavigate();
    const [slider, setSlider] = useState(1);

    const videoRef = useRef(null);


    useEffect(() => {
        let i = 0;
        let l = 4;
        // setInterval(() => {
        //     while (i <= l) {
        //         switchSlider(slider + 1);
        //     }
        // }, 10, []);
        const timer = setTimeout(() => {
            // while (i <= l) {
            switchSlider(slider + 1);
            // }
            console.log('This will run after 1 second!');
        }, 5000);
        return () => clearTimeout(timer);

    }, [slider]);

    const pressPlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };
    const switchSlider = (id) => {
        if (id <= 4) {
            // setSlider(1)
            setSlider(id);
        } else {
            setSlider(1);
        }
    };
    return (
        <div>
            <div className="relative">
                <div className="flex items-center lg:justify-between rounded-xl  lg:mt-0">
                    {/* <div className="mx-8 lg:px-5 lg:w-1/2 w-full">
                        <h2 className="text-3xl mt-4 ">
                            <span className="text-violet-600 font-extralight">Discover</span> new apartments from verified agents and landlords
                        </h2>
                        <div className="flex space-x-4 lg:space-x-8">
                            <button className="px-3 w-32 py-2 lg:w-40 lg:py-3 bg-green-600/80 rounded-md mt-8 text-white cursor-pointer shadow-sm">Search</button>
                            <button className="px-3 lg:w-40 py-3 bg-violet-600 rounded-md mt-8 text-white cursor-pointer shadow-sm">Premium Agent</button>
                        </div>
                    </div> */}
                    <div className="lg:w-full -z-1 lg:block">
                        <img src={rect} alt="Home landing" className="w-full md:hidden" />
                        <img src={home} alt="Home landing" className="hidden w-full md:block " />
                    </div>
                    <div className='absolute top-1'>
                        <div className='flex justify-between items-center w-full'>
                            <div className='px-8 md:mb-10 '>
                                <div className=''>
                                    <h2 className="text-4xl md:text-[128px] md:leading-[120px] mt-14 text-white font-extrabold font-urbanist tracking-[-1.16px]">
                                        Elevate your business with our growing fitness community
                                    </h2>
                                </div>
                                <div className='md:w-[800px] md:mt-10'>
                                    <p className='text-[32px] text-white hidden md:block font-urbanist font-medium tracking-wide'>
                                        Join the rapidly expanding FitCliq community and boost your business with more members and increased revenue
                                    </p>
                                </div>
                                <Link to="register">
                                    <div className='bg-fcPrimary text-white  md:bg-white shadow-md md:text-fcPrimary cursor-pointer md:border md:border-fcPrimary text-[18px] md:text-2xl w-40 md:w-80 text-center rounded-md py-3 mt-8 font-urbanist md:font-extrabold leading-[28px] tracking-[-0.32px]'>
                                        Get Started
                                    </div>
                                </Link>
                                {/* <div>jksdhfjhdsjf</div> */}
                            </div>
                            <div className='-bottom-10'>
                                <img src={miniphone} alt="" className='hidden md:block  w-[928px] h-[776px]' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex items-center  bg-[#F2F2F2] px-4 md:hidden z-50'>
                    <div className='-mt-10 -mb-5'>
                        <img src={phone} alt="" className='w-[646px] h-[157px] md:h-[387px]' />
                    </div>
                    <div className='pr-8 md:mt-15'>
                        <p className='text-xs -ml-6 pr-8 mt-5  mb-2 font-urbanist font-medium tracking-wide'>
                            Join the rapidly expanding FitCliq community and boost your business with more members and increased revenue
                        </p>
                    </div>
                </div>
                <div className='relative flex items-end justify-between mt-6 md:mt-0 md:flex-row-reverse md:bg-[#F2F2F2]'>
                    <div className='ml-4 mb-4 w-1/2 '>
                        <div className=' md:absolute md:top-0 flex justify-between md:pr-14 md:mt-10'>
                            <div className={`transition ease-in-out delay-50 md:w-4/5 ${slider === 1 ? '' : 'hidden'} `}>
                                <h5 className='text-sm font-semibold my-2 md:text-[52px] font-urbanist tracking-wide leading-10	'>
                                    Simplify Member Access
                                </h5>
                                <p className='text-xs text-[#767676] md:mt-8 md:text-base tracking-tight font-normal leading-[26px]'>
                                    FitCliq is an all-in-one fitness platform that provides users with access to a wide range of fitness options, including gyms, swimming pools, self-defense classes, soccer pitches, golf clubs, etc. The platform is designed to make fitness more convenient and accessible, giving you access to move more.
                                </p>
                            </div>
                            <div className={`transition ease-in-out delay-50 md:w-4/5 ${slider === 2 ? '' : 'hidden'}`}>
                                <h5 className='text-sm font-semibold my-2 md:text-[52px] font-urbanist tracking-wide leading-10	'>
                                    Increased Revenue
                                </h5>
                                <p className='text-base text-[#767676] md:mt-8 md:text-base tracking-tight font-normal leading-[26px]'>
                                    Join the rapidly expanding fitness community and watch your business thrive with more members and increased revenue
                                    FitCliq offers a pricing model with partners, where we help sell classes to users through our platform and make a profit of the resale at no cost to you.
                                </p>
                            </div>
                            <div className={`transition ease-in-out delay-50 md:w-4/5 ${slider === 3 ? '' : 'hidden'}`}>
                                <h5 className='text-sm font-semibold my-2 md:text-[52px] font-urbanist tracking-wide leading-10	'>
                                    Technical Support
                                </h5>
                                <p className='text-base text-[#767676] md:mt-8 md:text-base tracking-tight font-normal leading-[26px]'>
                                    FitCliq provides technical support and training to partners to ensure they can effectively use the platform.
                                    This includes assistance with setting up their facility and services on the platform, as well as ongoing support to ensure they are getting the most out of the platform.
                                </p>
                            </div>
                            <div className={`transition ease-in-out delay-50 md:w-4/5 ${slider === 4 ? '' : 'hidden'}`}>
                                <h5 className='text-sm font-semibold my-2 md:text-[52px] font-urbanist tracking-wide leading-10	'>
                                    Promotional Support
                                </h5>
                                <p className='text-base text-[#767676] md:mt-8 md:text-base tracking-tight font-normal leading-[26px]'>
                                    At FitCliq, we believe in celebrating fitness and building a thriving community.
                                    Our promotions are designed to empower fitness centers, trainers, and coaches, while also rewarding our dedicated users.
                                </p>
                            </div>
                            <div className='space-y-2 ml-4 mt-10 md:mt-0'>
                                <div className={`w-3 h-3 border ${slider === 1 ? 'border-fcPrimary bg-fcPrimary' : ''} rounded-full`} onClick={() => { switchSlider(1); }}></div>
                                <div className={`w-3 h-3  border ${slider === 2 ? 'border-fcPrimary bg-fcPrimary' : ''} rounded-full cursor-pointer`} onClick={() => { switchSlider(2); }}></div>
                                <div className={`w-3 h-3 border ${slider === 3 ? 'border-fcPrimary bg-fcPrimary' : ''} rounded-full cursor-pointer`} onClick={() => { switchSlider(3); }}></div>
                                <div className={`w-3 h-3 border ${slider === 4 ? 'border-fcPrimary bg-fcPrimary' : ''} rounded-full cursor-pointer`} onClick={() => { switchSlider(4); }}></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/2 md:-mt-20 z-100'>
                        <img src={man} alt="" className=' h-[180px] md:hidden' />
                        <img src={manReverse} alt="" className=' h-[380px] hidden md:block' />
                    </div>
                </div>
            </div>
            <div className=' bg-[#F2F2F2] md:bg-white px-4 md:py-10 pt-6 md:flex md:space-x-64'>
                <div className='md:py-24 md:px-24 md:w-1/2'>
                    <h5 className='text-center md:text-left text-md md:text-[64px]  text-[#1A1A1A] md:text-fcPrimary md:font-extrabold font-urbanist tracking-[-0.94px] md:leading-[62px]	'>
                        Gain access to thousands of potential customers
                    </h5>
                </div>
                <div className='flex  items-center  py-6 md:flex-col md:space-y-10 md:items-start md:w-[480px] md:-mr-42 '>
                    <div className="items-center md:flex md:justify-between md:space-x-10">
                        <div className="bg-[#D9D9D9] p-4 rounded-2xl w-[87px] h-[87px] flex items-center justify-center">
                            <UsersIcon className="w-10 h-10 text-gray-600" />
                        </div>
                        <p className='text-[10px] text-center mt-3 text-[#767676] md:text-[#808080] md:text-[24px] md:font-normal md:leading-6 md:text-left'>We connect you to new customers everyday</p>
                    </div>
                    <div className=" md:flex md:justify-between md:space-x-10">
                        <div className="bg-[#D9D9D9] p-4 rounded-2xl w-[87px] h-[87px] flex items-center justify-center ml-8 md:ml-0">
                            <AdjustmentsHorizontalIcon className="w-10 h-10 text-gray-600" />
                        </div>
                        <p className='text-[10px] text-center  mt-3 text-[#767676] md:text-[#808080] md:text-[24px] md:font-normal md:leading-6 md:text-left'>
                            Provide you with a customized app to monitor client check-in and revenue</p>
                    </div>
                    <div className=" md:flex md:justify-between md:space-x-10">
                        <div className="bg-[#D9D9D9] p-4 rounded-2xl w-[87px] h-[87px] flex items-center justify-center ">
                            <CheckIcon className="w-10 h-10 text-gray-600" />
                        </div>
                        <p className='text-[10px] text-center mt-3 text-[#767676] md:text-[#808080] md:text-[24px] md:font-normal md:leading-6 md:text-left'>
                            Get paid instantly a client successfully checks in
                        </p>
                    </div>
                </div>
            </div>
            <div className="transform md:bg-[#F2F2F2]">
                <div className='text-center  text-lg'>
                    <h3 className='md:text-[64px] md:text-fcPrimary md:py-4 font-bold font-urbanist md:font-extrabold md:leading-[64px] md:tracking-[-0.94px]'>
                        Transform Your Fitness Business with FitCliq
                    </h3>
                </div>
                <div className='relative'>
                    <video ref={videoRef} >
                        <source src={video} type="video/mp4" />
                    </video>
                    <div className='absolute top-[50%] left-[40%] ' onClick={pressPlay}>
                        {
                            isPlaying ?
                                <PauseCircleIcon className={`text-white w-16 h-16 md:w-32  cursor-pointer`} /> :

                                <PlayCircleIcon className='text-white w-16 h-16 md:w-32 cursor-pointer' />
                        }
                    </div>
                </div>
            </div>
            <div className="mt-5 flex items-center justify-center">
                <button
                    onClick={() => {
                        navigate("register");
                    }}
                    className="bg-orange text-white py-3 md:py-5 w-3/5 md:w-2/5 rounded-lg cursor-pointer font-urbanist md:font-semibold md:leading-[32px] md:tracking-[-0.32px]">Get Started</button>
            </div>
        </div>
    );
};

export default Home;