import React, { useState } from 'react';
// import logo from './assets/img/logo.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 


const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [bizName, setBizName] = useState('');
    const [address, setAddress] = useState('');
    const [area, setArea] = useState('');
    const [studio, setStudio] = useState('');
    const [location, setLocation] = useState('');
    const [access, setAccess] = useState('');
    const [priceMonth, setPriceMonth] = useState('');
    const [priceSession, setPriceSession] = useState('');
    const [schedule, setSchedule] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate() + 1));



    const processSubmit = async (e) => {
        e.preventDefault();
        try {
            let cm = '';

            if (!error) {
                // await addDoc(collection(db, "order"), {
                //     name: name,

                // });
                const msg = `
                Hello <i>Fitcliqa</i>,
                <p>
    A New partner sign up. See detail below;
    <p>
            Business Name: ${bizName} <br/>
            Full Name: ${name} <br/>
            Phone: ${phone}<br/>
            Email: ${email}<br/>
            Address: ${address}<br/>
            Area: ${area} <br/>
            Studio: ${studio}<br/>
            Location: ${location}<br/>
            Access: ${access}<br/>
            Price Monthly: ${priceMonth}<br/>
            Price Session: ${priceSession}<br/>
            Schedule: ${startDate}
            

            <p>Let's Gooooooo!
                `;
                window.Email.send({
                    // Host: "smtp.elasticemail.com",
                    // Username: "tgif@edenlife.ng",
                    // Password: "4D9B3949AC6EC12973B2A9F52F4C4ECAAAE8",
                    // Port: "2525",
                    SecureToken: "89ff2193-c03a-4815-8b5c-9b2dd8be83d8",
                    To: 'partners@fitcliq.co',
                    // To: 'tgif@edenlife.ng,francis@edenlife.ng,blessing@edenlife.ng,ajoke@edenlife.ng',
                    From: "tgif@edenlife.ng",
                    Subject: "Gym Owner",
                    Body: `${msg}`
                }).then(
                    message => console.log(message)
                );
                setMessage(`Thank you for your interest in becoming a partner, we will reach out to you immediately!`);
                setSuccess(true);
            }
            // if (type !== "custom") {
            //     // navigate("https://paystack.com/pay/eden-tgif-standard");
            //     window.location.replace(`https://paystack.com/pay/eden-tgif-${type}`);

            // }
            // https://paystack.com/pay/eden-tgif-standard
            // https://paystack.com/pay/eden-tgif-medium
        } catch (error) {
            console.log(error.message);
            setMessage(error.message);
        }
    };
    return (
        <div className="App mx-auto container mt-2">
            {/* <div className="header">
              <img src={logo} className="w-32" alt="Logo" />
          </div> */}
            <div className="bg-green-700 mx-4 text-white text-center">{message}</div>
            {
                !success && (<form onSubmit={processSubmit}>
                    <div className="main mt-10 mx-4">
                        <h1 className=''>FitCliq Gym Partnership sign up</h1>
                        <p className="mt-5">Join the movement, let us bring customers to your gym and easily manage your studio.</p>
                        <div className='mt-10 w-full space-y-5'>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Name</p>
                                </div>
                                <div>
                                    <input name="name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Email</p>
                                </div>
                                <div>
                                    <input name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Phone number</p>
                                </div>
                                <div>
                                    <input name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Business Name</p>
                                </div>
                                <div>
                                    <input name="bizName" value={bizName} onChange={(e) => setBizName(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Address</p>
                                </div>
                                <div>
                                    <input name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Area</p>
                                </div>
                                <div>
                                    <input name="area" value={area} onChange={(e) => setArea(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Fitness Studio Type</p>
                                </div>
                                <div>
                                    <input name="studio" value={studio} onChange={(e) => setStudio(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Number of locations</p>
                                </div>
                                <div>
                                    <input name="location" value={location} onChange={(e) => setLocation(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Access Type</p>
                                </div>
                                <div>
                                    <select name="access" className='mt-2'>
                                        <option>Full Access</option>
                                        <option>Single Class</option>
                                        <option>Both</option>
                                    </select>
                                    {/* <input name="access" value={access} onChange={(e) => setAccess(e.target.value)} /> */}
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Price per Month</p>
                                </div>
                                <div>
                                    <input name="priceMonth" value={priceMonth} onChange={(e) => setPriceMonth(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Price per Session</p>
                                </div>
                                <div>
                                    <input name="priceSession" value={priceSession} onChange={(e) => setPriceSession(e.target.value)} />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <p className='leadText'>Schedule Call time</p>
                                </div>
                                <div>
                                    {/* <input name="schedule" value={schedule} onChange={(e) => setSchedule(e.target.value)} /> */}
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        className='outline-none w-full'
                                    />
                                </div>
                            </div>
                            <div className='inputContainer'>
                                <div>
                                    <button type='submit' className='bg-fcPrimary text-white w-full p-4 rounded-md hover:bg-fcPrimary/90'>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>)
            }

        </div>
    );
};

export default Register;