// import logo from './assets/img/logo.png';
// import rect from './assets/img/Rectangle.png';
// import phone from './assets/img/Phone.png';
// import vest from './assets/img/vest.png';
// // import './App.css';
// import { Link } from "react-router-dom";

// function App() {
//   return (
//     <div className="App mx-auto container mt-2">
//       <header className='flex justify-between '>
//         <div>
//           <img src={logo} className="w-32" alt="Logo" />
//         </div>
//         <div className="bg-orange/90 rounded-full px-5 flex items-center text-base text-white hover:bg-orange/80 transition-opacity hover:cursor-pointer mr-10">
//           <Link to="register" >Sign up</Link>
//         </div>
//       </header>
//       <div className='flex justify-between'>
//         <div className='relative mt-10 z-10'>
//           <img src={rect} alt="" />
//           <div className='flex justify-between'>
//             <div className="absolute top-20 px-10">
//               <div className='flex'>
//                 <div>
//                   <div className='w-[600px] mt-10'>
//                     <h2 className='text-6xl text-white font-bold'>
//                       Boost your business with our growing fitness community
//                     </h2>
//                     <p className='text-white mt-10'>
//                       Join the rapidly expanding Motility  community and boost <br />your business with more members and increased revenue
//                     </p>
//                   </div>
//                   <div className='mt-10'>
//                     <Link to="register" className='bg-orange text-white p-4 '>Get Started</Link>
//                   </div>
//                 </div>
//                 <div className="items-end right-0">
//                   <img src={phone} alt="Phone" />
//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="access bg-[#F2F2F2] mr-10 flex justify-between">
//         <div className='w-2/5'>
//           <img src={vest} alt="" className='-mt-10 z-100' />
//         </div>
//         <div>
//           <div>
//             <h2>Simplify Member Access</h2>
//             <p>
//               FitCliq is an all-in-one fitness platform that provides users with access to a wide range of fitness options, including gyms, swimming pools, self-defense classes, soccer pitches, golf clubs, etc. The platform is designed to make fitness more convenient and accessible, giving you access to move more.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default App;

// import Prelaunch from "./views/Prelaunch";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";

function App() {
  return (
    <BrowserRouter>
      <Routes />
      {/* <p className="text-xl">Hello world!</p> */}
    </BrowserRouter>
  );
}

export default App;
