import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import fb from "../../assets/img/Icons/facebook.png";
import tw from "../../assets/img/Icons/twitter.png";
import li from "../../assets/img/Icons/li.png";
import ig from "../../assets/img/ig.png";

import { Bars3BottomRightIcon, MagnifyingGlassIcon as SearchIcon, XMarkIcon, sort } from "@heroicons/react/20/solid";
import Logo from '../../components/Logo';
import { Menu } from '@headlessui/react';


const Default = () => {
    const [isMobile, setIsMobile] = useState(false);
    return (
        <div className='flex-1'>
            <header className="flex justify-between items-center mx-auto px-5 pt-3 pb-2 border-b border-fcGray7 py-4">
                <div className="one flex justify-between items-center space-x-8">
                    <div>
                        <Logo />
                    </div>
                    {/* <div className="search hidden lg:flex justify-around items-center bg-fcGray6 w-96 rounded-3xl h-10">
                        <div className="categories flex w-1/5 justify-between items-center bg-white rounded-full px-2 py-1">
                            <span className="font-bold text-xs">Pitch</span>
                            <span>
                                <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z" fill="#212121" />
                                </svg>
                            </span>
                        </div>
                        <input type="text" placeholder="City, neighbourhood" class="outline-none h-6 w-3/5 bg-transparent placeholder:text-xs placeholder:text-gray-500" />
                        <SearchIcon className="w-6 h-6 text-gray-600" />
                    </div> */}
                </div>
                <div className="two lg:flex justify-between items-center space-x-8 text-sm hidden ">
                    {/* <div className="link flex space-x-4 ">
                        <Link to="/">Home</Link>
                        <Link to="/pitch" className="">Pitches</Link>
                        <Link to="/team">Teams</Link>
                        <Link to="/login">Login</Link>
                    </div> */}
                    {/* <div className="list  px-6 py-2 rounded-full bg-fcPrimary">
                        <Link to="/register" className=" text-white text-base ">
                            Sign up
                        </Link>
                    </div> */}
                </div>
                <div className="mobile lg:hidden">
            
                </div>
            </header>
            <Outlet />
            <footer className=" border-t border-fcGray7 mt-8 text-white px-4 h-32 py-4 flex items-center justify-between md:flex-col md:items-start md:pt-14 md:space-y-4 md:mb-5">
                <div className=''>
                    <Logo />
                </div>
                <div className="socials flex ">
                    <a href="https://instagram.com/fitcliqhq">
                        <img src={ig} alt="" />
                    </a>
                    <a href="https://facebook.com/fitcliqhq">
                        <img src={fb} alt="" />
                    </a>
                    <a href="https://twitter/fitcliqhq">
                        <img src={tw} alt="" />
                    </a>
                    <a href="https://linkedin.com/in/fitcliqhq">
                        <img src={li} alt="" />
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Default;