import * as React from "react";
import { useRoutes } from "react-router-dom";
import Home from "./views/Home";
// import Home2 from "./views/Home2";
// import Dashboard from "./views/dashboard";
// import Profile from "./views/dashboard/profile/Profile";
// import { Login, Register } from "./views/auth";
// import Register from "./views/auth/Register/Register";
import DefaultLayout from './layouts/default';
import Register from "./views/Register";
// import DashboardLayout from './layouts/dashboard';
// import Search from "./views/search";
// import { PitchDetail } from "./views/pitch";
// import TeamDetail from "./views/team/TeamDetail";
// import { Home as DashboardHome, Invite } from './views/dashboard';
// import { Players, AddPlayer } from "./views/dashboard/player";
// import { CreateSeason } from "./views/dashboard/season";
// import  from "./views/dashboard/player/AddPlayer";




export function Routes() {
  let element = useRoutes([
    {
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "register",
          element: <Register />,
        },
        // {
        //   path: "pitch/:id",
        //   element: <PitchDetail />,
        // },
        // {
        //   path: "team/:id",
        //   element: <TeamDetail />,
        // },

        // {
        //   path: "about",
        //   element: <About />,
        // },
        // {
        //   path: "contact",
        //   element: <Contact />,
        // },

      ],
    },
    // {
    //   path: "login",
    //   element: <Login />,
    // },
    // {
    //   path: "register",
    //   element: <Register />,
    // },
    // {
    //   path: "/dashboard",
    //   element: <DashboardLayout />,
    //   children: [
    //     {
    //       index: true,
    //       element: <DashboardHome />,
    //     },

    //     {
    //       path: "invite",
    //       element: <Invite />,
    //     },
    //     {
    //       path: "players",
    //       element: <Players />,
    //     },
    //     {
    //       path: "add-player",
    //       element: <AddPlayer />,
    //     },
    //     {
    //       path: "season",
    //       element: <CreateSeason />,
    //     },
    //     // {
    //     //   path: "statistics",
    //     //   element: <Statistic />,
    //     // },
    //     // {
    //     //   path: "profile",
    //     //   element: <Profile />,
    //     // },
    //     // {
    //     //   path: "messages",
    //     //   element: <Messages />,
    //     // },
    //     {
    //       path: "settings",
    //       children: [
    //         // {
    //         //   index: true,
    //         //   element: <Settings />,
    //         // },
    //         // {
    //         //   path: "terms",
    //         //   element: <Terms />,
    //         // },
    //       ],
    //     },
    //   ],
    // },
  ]);

  return element;
}
